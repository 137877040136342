import { DateTime } from "luxon";

export function getUserTimezone() {
  return DateTime.local().zoneName;
}

export function convertToUTC(dateString, timezone) {
  const localTime = DateTime.fromISO(dateString, { zone: timezone });
  return localTime.toUTC().toISO();
}
