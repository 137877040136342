<template>
    <div>
        <div v-if="this.role === 'admin'">
            <AppHeader />
        </div>

        <div class="container" style="margin-top: 10px;">
            <div v-if="this.isHoliday" class="w-100 d-flex align-items-center justify-content-center">
                <h6 class="text-danger" style="margin: 0px;">Market is {{ this.holidayStatus }} due to {{
                    this.holidayReason }}.</h6>
            </div>

            <div v-if="!this.ibkrServerStatus && this.ibkrClientStatus"
                class="w-100 d-flex align-items-center justify-content-center">
                <h6 class="text-danger" style="margin: 0px;">IBKR Gateway or TWS is currently connected on another
                    device or platform. Please
                    verify your server gateway connection before using this webpage.</h6>
            </div>

            <!-- <div v-if="!this.recurringScheduleOrderStatus"
                class="w-100 d-flex align-items-center justify-content-center">
                <h6 class="text-danger" style="margin: 0px;">Recurring sell has stopped. To restart, please re-login or
                    restart IBKR and
                    wait.</h6>
            </div> -->

            <div v-if="this.role === 'user'" class="w-100 d-flex align-items-start justify-content-between">
                <div>
                    <h6 v-if="this.role === 'admin'" class="mb-1">
                        User IBKR Status :
                        <span :style="{ color: this.setting.ibkrOrder ? 'green' : 'red' }">
                            {{ this.setting.ibkrOrder ? 'connected' : 'disconnected' }}
                        </span>
                        <svg @click="() => this.toggleIbkrOrder(!this.setting.ibkrOrder)"
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                            class="mx-2 bi bi-arrow-repeat" style="cursor: pointer; margin-bottom: 2px">
                            <path fill="#555"
                                d="M13 2v10h-2V2zm3.78 1.728l.809.589a9.5 9.5 0 1 1-11.178 0l.808-.59l1.178 1.617l-.808.59a7.5 7.5 0 1 0 8.822 0l-.808-.59z" />
                        </svg>
                    </h6>

                    <h6 v-if="this.role === 'user'" class="mb-1">
                        User Status :
                        <span :style="{ color: 'green' }">
                            {{ 'logged' }}
                        </span>
                        <svg @click="logout" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            viewBox="0 0 24 24" class="mx-2 bi bi-arrow-repeat"
                            style="cursor: pointer; margin-bottom: 2px">
                            <path fill="#555"
                                d="M13 2v10h-2V2zm3.78 1.728l.809.589a9.5 9.5 0 1 1-11.178 0l.808-.59l1.178 1.617l-.808.59a7.5 7.5 0 1 0 8.822 0l-.808-.59z" />
                        </svg>
                    </h6>

                    <h6 class="mb-1">
                        IBKR Client Status :
                        <span :style="{ color: this.ibkrClientStatus ? 'green' : 'red' }">
                            {{ this.ibkrClientStatus ? 'online' : 'offline' }}
                        </span>
                        <svg v-if="!this.ibkrClientStatus" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" class="mx-2 bi bi-arrow-repeat" :class="{ spinning }"
                            style="cursor: pointer" viewBox="0 0 16 16" @click="triggerSpin">
                            <path
                                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                            <path fill-rule="evenodd"
                                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                        </svg>
                    </h6>

                    <h6 class="mb-1">
                        IBKR Server Status :
                        <span :style="{ color: ibkrServerStatus ? 'green' : 'red' }">
                            {{ ibkrServerStatus ? 'online' : 'offline' }}
                        </span>
                        <svg v-if="ibkrServerStatus" @click="disconnectIbkr" xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16" viewBox="0 0 24 24" class="mx-2 bi bi-arrow-repeat"
                            style="cursor: pointer; margin-bottom: 2px">
                            <path fill="#555"
                                d="M13 2v10h-2V2zm3.78 1.728l.809.589a9.5 9.5 0 1 1-11.178 0l.808-.59l1.178 1.617l-.808.59a7.5 7.5 0 1 0 8.822 0l-.808-.59z" />
                        </svg>
                    </h6>
                </div>
            </div>

            <!-- Data Tables for Call and Put sides -->
            <div id="dataTable">
                <div v-if="this.role === 'user'" class="d-flex flex-column align-items-center justify-content-center" style="margin-top: 15px;">
                    <div class="w-100 d-flex align-items-center justify-content-center"
                        style="margin-bottom: 80px; max-width: 800px">
                        <div class="w-100 text-center"
                            style="background-color: #ff6eda; padding: 15px; border-radius: 12px;">
                            <h3 class="mb-2 text-white">Base Value : <b>{{ formatBaseValue() }}</b></h3>
                        </div>
                    </div>

                    <div class="w-100 d-none align-items-center justify-content-between" style="margin-bottom: 80px;">
                        <div class="text-center">
                            <h3 class="mb-2">CALL</h3>
                            <h5>Strike Price: <b>{{ this.latestData?.putcall1 || 0 }}</b></h5>
                            <h6>Ask Price: <b>{{ this.latestData?.putcallAskPrice1 || 0 }}</b></h6>
                            <h6>Bid Price: <b>{{ this.latestData?.putcallBidPrice1 || 0 }}</b></h6>
                        </div>

                        <div class="text-center">
                            <h3 class="mb-2">PUT</h3>
                            <h5>Strike Price: <b>{{ this.latestData?.callput1 || 0 }}</b></h5>
                            <h6>Ask Price: <b>{{ this.latestData?.callputAskPrice1 || 0 }}</b></h6>
                            <h6>Bid Price: <b>{{ this.latestData?.callputBidPrice1 || 0 }}</b></h6>
                        </div>
                    </div>

                    <div class="w-100 d-flex align-items-center justify-content-between"
                        style="margin-bottom: 80px; max-width: 800px">
                        <b-button
                            v-if="this.setting.stopRecurringBuyPutOrder || this.selectedOrderPutType === 'TRAIL_VALUE'"
                            class="me-2 btn-success" style="width: 250px; height: 50px"
                            :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading" @click="() => {
                                if (this.selectedOrderPutType === 'TRAIL_VALUE') {
                                    handleBuyOrder(
                                        this.setting.putOrderSize,
                                        'TRAIL_VALUE',
                                        Number(this.latestData?.putcallAskPrice1),
                                        Number(this.selectedPutTrailValue),
                                        this.selectedPutTrailPercentage,
                                        this.selectedPutStrike,
                                        this.latestData?.baseValue,
                                        this.latestData?.date,
                                        'C'
                                    )
                                } else {
                                    handleBuyOrder(
                                        this.setting.putOrderSize,
                                        'MKT',
                                        Number(this.latestData?.putcallAskPrice1),
                                        Number(this.latestData?.putcallAskPrice1),
                                        this.selectedPutTrailPercentage,
                                        this.selectedPutStrike,
                                        this.latestData?.baseValue,
                                        this.latestData?.date,
                                        'C'
                                    )
                                }
                            }">Up</b-button>

                        <b-button v-if="!this.setting.stopRecurringBuyPutOrder && this.selectedOrderPutType === 'MKT'"
                            :class="this.setting.isPlacingPutOrder ? 'me-2 btn-danger' : 'me-2 btn-warning'"
                            style="width: 250px; height: 50px" :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading"
                            @click="recurringOrder('PUT', this.selectedOrderPutType, false, this.setting.selectedRecurringBuyPutDuration)">{{
                                this.setting.isPlacingPutOrder ? 'Stop' : 'Up' }}</b-button>

                        <b-button
                            v-if="this.setting.stopRecurringBuyCallOrder || this.selectedOrderCallType === 'TRAIL_VALUE'"
                            class="ms-2 btn-success" style="width: 250px; height: 50px"
                            :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading" @click="() => {
                                if (this.selectedOrderPutType === 'TRAIL_VALUE') {
                                    handleBuyOrder(
                                        this.setting.callOrderSize,
                                        'TRAIL_VALUE',
                                        Number(this.latestData?.callputAskPrice1),
                                        Number(this.selectedCallTrailValue),
                                        this.selectedCallTrailPercentage,
                                        this.selectedCallStrike,
                                        this.latestData?.baseValue,
                                        this.latestData?.date,
                                        'P'
                                    )
                                } else {
                                    handleBuyOrder(
                                        this.setting.callOrderSize,
                                        'MKT',
                                        Number(this.latestData?.callputAskPrice1),
                                        Number(this.latestData?.callputAskPrice1),
                                        this.selectedCallTrailPercentage,
                                        this.selectedCallStrike,
                                        this.latestData?.baseValue,
                                        this.latestData?.date,
                                        'P'
                                    )
                                }
                            }">Down</b-button>

                        <b-button v-if="!this.setting.stopRecurringBuyCallOrder && this.selectedOrderCallType === 'MKT'"
                            :class="this.setting.isPlacingCallOrder ? 'ms-2 btn-danger' : 'ms-2 btn-warning'"
                            style="width: 250px; height: 50px" :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading"
                            @click="recurringOrder('CALL', this.selectedOrderCallType, false, this.setting.selectedRecurringBuyCallDuration)">{{
                                this.setting.isPlacingCallOrder ? 'Stop' : 'Down' }}</b-button>
                    </div>
                </div>

                <div v-if="this.role === 'admin'" style="display: flex; justify-content: space-between; align-items: flex-start; margin-top: 15px;">
                    <div style="width: 49%;">
                        <DataTable side="PUT" :latestData="latestData" :selectedLimit="selectedPutLimit"
                            :limits="putLimits" @update:selectedLimit="savePutLimit" />

                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div>
                                <div class="d-flex align-items-center">
                                    <p>
                                        Call Strike: <b>{{ this.latestData?.putcall1 || 0 }}</b>
                                    </p>
                                    <p style="margin-right: 10px; margin-left: 10px;">
                                        Ask Price: <b>{{ this.latestData?.putcallAskPrice1 || 0 }}</b>
                                    </p>
                                    <p>
                                        Bid Price: <b>{{ this.latestData?.putcallBidPrice1 || 0 }}</b>
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <div class="mx-2">
                                    <label for="number-select-put" style="margin-right: 5px">Size :</label>
                                    <select v-model="this.setting.putOrderSize" id="number-select-put"
                                        :disabled="this.isSettingLoading"
                                        :onChange="(e) => saveSelectedPutOrderSize(e.currentTarget.value)">
                                        <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                            {{ number }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderPutType === 'TRAIL_VALUE'" class="mx-2">
                                    <label for="trail-type-put-value" style="margin-right: 5px">Trail
                                        Value
                                        :</label>
                                    <select v-model="selectedPutTrailValue" id="trail-type-put-value">
                                        <option v-for="value in trailValue" :key="value" :value="value">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderPutType === 'TRAIL_PERCENTAGE'" class="mx-2">
                                    <label for="trail-type-put" style="margin-right: 5px">Trail Percentage
                                        :</label>
                                    <select v-model="selectedPutTrailPercentage" id="trail-type-put">
                                        <option v-for="percentage in trailPercentage" :key="percentage"
                                            :value="percentage">
                                            {{ percentage }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderPutType === 'MKT'" class="mx-2">
                                    <label for="put-duration" style="margin-right: 5px">Duration (In
                                        Minutes) : </label>
                                    <select v-model="this.setting.selectedRecurringBuyPutDuration" id="put-duration"
                                        :onChange="(e) => saveSelectedPutDuration(e.currentTarget.value)"
                                        :disabled="this.setting.isPlacingPutOrder || this.isSettingLoading">
                                        <option v-for="duration in durationArr" :key="duration" :value="duration">
                                            {{ duration }}
                                        </option>
                                    </select>
                                </div>

                                <!-- <div v-if="this.selectedOrderPutType === 'MKT' && !this.setting.stopRecurringBuyPutOrder"
                                    class="mx-2">
                                    <label for="call-duration" style="margin-right: 5px">Sell Duration :
                                    </label>
                                    <select v-model="this.setting.selectedRecurringSellCallDuration" id="call-duration"
                                        :disabled="this.isSettingLoading"
                                        :onChange="(e) => this.saveRecurringSellCallDuration(e.currentTarget.value)">
                                        <option v-for="duration in durationArr" :key="duration" :value="duration">
                                            {{ duration }}
                                        </option>
                                    </select>
                                </div> -->

                                <b-button
                                    v-if="this.setting.stopRecurringBuyPutOrder || this.selectedOrderPutType === 'TRAIL_VALUE'"
                                    class="mx-2 btn-success" :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading"
                                    @click="() => {
                                        if (this.selectedOrderPutType === 'TRAIL_VALUE') {
                                            handleBuyOrder(
                                                this.setting.putOrderSize,
                                                'TRAIL_VALUE',
                                                Number(this.latestData?.putcallAskPrice1),
                                                Number(this.selectedPutTrailValue),
                                                this.selectedPutTrailPercentage,
                                                this.selectedPutStrike,
                                                this.latestData?.baseValue,
                                                this.latestData?.date,
                                                'C'
                                            )
                                        } else {
                                            handleBuyOrder(
                                                this.setting.putOrderSize,
                                                'MKT',
                                                Number(this.latestData?.putcallAskPrice1),
                                                Number(this.latestData?.putcallAskPrice1),
                                                this.selectedPutTrailPercentage,
                                                this.selectedPutStrike,
                                                this.latestData?.baseValue,
                                                this.latestData?.date,
                                                'C'
                                            )
                                        }
                                    }">Buy</b-button>

                                <b-button
                                    v-if="!this.setting.stopRecurringBuyPutOrder && this.selectedOrderPutType === 'MKT'"
                                    :class="this.setting.isPlacingPutOrder ? 'mx-2 btn-danger' : 'mx-2 btn-warning'"
                                    :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading"
                                    @click="recurringOrder('PUT', this.selectedOrderPutType, false, this.setting.selectedRecurringBuyPutDuration)">{{
                                        this.setting.isPlacingPutOrder ? 'Stop' : 'Buy' }}</b-button>
                            </div>
                        </div>
                    </div>

                    <div style="width: 49%;">
                        <DataTable side="CALL" :latestData="latestData" :selectedLimit="selectedCallLimit"
                            :limits="callLimits" @update:selectedLimit="saveCallLimit" />

                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div>
                                <div class="d-flex align-items-center">
                                    <p>
                                        Put Strike: <b>{{ this.latestData?.callput1 || 0 }}</b>
                                    </p>
                                    <p style="margin-right: 10px; margin-left: 10px;">
                                        Ask Price: <b>{{ this.latestData?.callputAskPrice1 || 0 }}</b>
                                    </p>
                                    <p>
                                        Bid Price: <b>{{ this.latestData?.callputBidPrice1 || 0 }}</b>
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-center">
                                <div class="mx-2">
                                    <label for="number-select-call" style="margin-right: 5px">Size :</label>
                                    <select v-model="this.setting.callOrderSize" id="number-select-call"
                                        :disabled="this.isSettingLoading"
                                        :onChange="(e) => saveSelectedCallOrderSize(e.currentTarget.value)">
                                        <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                            {{ number }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderCallType === 'TRAIL_VALUE'" class="mx-2">
                                    <label for="trail-type-call-value" style="margin-right: 5px">Trail
                                        Value
                                        :</label>
                                    <select v-model="selectedCallTrailValue" id="trail-type-call-value">
                                        <option v-for="value in trailValue" :key="value" :value="value">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderCallType === 'TRAIL_PERCENTAGE'" class="mx-2">
                                    <label for="trail-type-call" style="margin-right: 5px">Trail
                                        Percentage
                                        :</label>
                                    <select v-model="selectedCallTrailPercentage" id="trail-type-call">
                                        <option v-for="percentage in trailPercentage" :key="percentage"
                                            :value="percentage">
                                            {{ percentage }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderCallType === 'MKT'" class="mx-2">
                                    <label for="call-duration" style="margin-right: 5px">Duration (In
                                        Minutes) : </label>
                                    <select v-model="this.setting.selectedRecurringBuyCallDuration" id="call-duration"
                                        :onChange="(e) => saveSelectedCallDuration(e.currentTarget.value)"
                                        :disabled="this.setting.isPlacingCallOrder || this.isSettingLoading">
                                        <option v-for="duration in durationArr" :key="duration" :value="duration">
                                            {{ duration }}
                                        </option>
                                    </select>
                                </div>

                                <!-- <div v-if="this.selectedOrderCallType === 'MKT' && !this.setting.stopRecurringBuyCallOrder"
                                    class="mx-2">
                                    <label for="put-duration" style="margin-right: 5px;">Sell Duration :
                                    </label>
                                    <select v-model="this.setting.selectedRecurringSellPutDuration" id="put-duration"
                                        :disabled="this.isSettingLoading"
                                        :onChange="(e) => this.saveRecurringSellPutDuration(e.currentTarget.value)">
                                        <option v-for="duration in durationArr" :key="duration" :value="duration">
                                            {{ duration }}
                                        </option>
                                    </select>
                                </div> -->

                                <b-button
                                    v-if="this.setting.stopRecurringBuyCallOrder || this.selectedOrderCallType === 'TRAIL_VALUE'"
                                    class="mx-2 btn-success" :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading"
                                    @click="() => {
                                        if (this.selectedOrderPutType === 'TRAIL_VALUE') {
                                            handleBuyOrder(
                                                this.setting.callOrderSize,
                                                'TRAIL_VALUE',
                                                Number(this.latestData?.callputAskPrice1),
                                                Number(this.selectedCallTrailValue),
                                                this.selectedCallTrailPercentage,
                                                this.selectedCallStrike,
                                                this.latestData?.baseValue,
                                                this.latestData?.date,
                                                'P'
                                            )
                                        } else {
                                            handleBuyOrder(
                                                this.setting.callOrderSize,
                                                'MKT',
                                                Number(this.latestData?.callputAskPrice1),
                                                Number(this.latestData?.callputAskPrice1),
                                                this.selectedCallTrailPercentage,
                                                this.selectedCallStrike,
                                                this.latestData?.baseValue,
                                                this.latestData?.date,
                                                'P'
                                            )
                                        }
                                    }">Buy</b-button>

                                <b-button
                                    v-if="!this.setting.stopRecurringBuyCallOrder && this.selectedOrderCallType === 'MKT'"
                                    :class="this.setting.isPlacingCallOrder ? 'mx-2 btn-danger' : 'mx-2 btn-warning'"
                                    :disabled="!ibkrServerStatus || this.latestData === null || this.isSettingLoading"
                                    @click="recurringOrder('CALL', this.selectedOrderCallType, false, this.setting.selectedRecurringBuyCallDuration)">{{
                                        this.setting.isPlacingCallOrder ? 'Stop' : 'Buy' }}</b-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="data-table-container" v-if="this.role === 'admin'" style="margin-top: 15px;">
                    <table class="data-table table table-bordered table-striped table-hover table-responsive"
                        style="margin: 0px">
                        <thead class="sticky-tr-0">
                            <tr>
                                <td colspan="6" style="text-align: left; font-weight: bold; background-color: #dbdbdb;">
                                    <div class="mt-1 d-flex justify-content-between align-items-center">
                                        <h5>Account Details & Financial Instruments</h5>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h6 class="mx-2">Daily PnL : {{ this.accountSummary?.dailyPnL || 0 }}</h6>
                                            <h6 class="mx-2">Unrealized PnL : {{ this.accountSummary?.unrealizedPnL ||
                                                0 }}</h6>
                                            <h6 class="mx-2">Realized PnL : {{ this.accountSummary?.realizedPnL || 0 }}
                                            </h6>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="sticky-tr">
                                <td @click="() => this.sortData('date')">
                                    <b>Total {{ this.todayDate }}</b> {{ this.sortFields.date ? 'desc' : 'asc' }}
                                </td>
                                <td @click="() => this.sortData('position')" style="cursor: pointer;">
                                    <b>Position </b> {{ this.sortFields.position ? 'desc' : 'asc' }}
                                </td>
                                <td @click="() => this.sortData('dailyPnL')" style="cursor: pointer;">
                                    <b>Daily PnL </b> {{ this.sortFields.dailyPnL ? 'desc' : 'asc' }}
                                </td>
                                <td @click="() => this.sortData('unrealizedPnL')" style="cursor: pointer;">
                                    <b>UnRealized PnL </b> {{ this.sortFields.unrealizedPnL ? 'desc' : 'asc' }}
                                </td>
                                <td @click="() => this.sortData('realizedPnL')" style="cursor: pointer;">
                                    <b>Realized PnL </b> {{ this.sortFields.realizedPnL ? 'desc' : 'asc' }}
                                </td>
                                <td>
                                    <b>Last</b>
                                </td>
                            </tr>
                            <tr v-for="item in this.positionData" :key="item.conId">
                                <td>{{ item?.localSymbol && convertOptionSymbol(item?.localSymbol) || 'N/A' }}</td>
                                <td>{{ item?.position || 0 }}</td>
                                <td>{{ item?.dailyPnL || 0 }}</td>
                                <td>{{ item?.unrealizedPnL || 0 }}</td>
                                <td>{{ item?.realizedPnL || 0 }}</td>
                                <td>{{ item?.last || 0 }}</td>
                            </tr>
                            <tr v-if="this.positionData.length === 0" style="height: 193px;">
                                <td colspan="6" style="height: 100%">
                                    <div class="h-100 w-100 d-flex align-items-center justify-content-center">
                                        No Financial Instruments Available
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="this.role === 'admin'" style="margin-top: 15px;  margin-bottom: 35px">
                    <b-tabs>
                        <!-- <b-tab title="Schedule Order Data">
                            <ScheduleOrderData />
                        </b-tab> -->
                        <b-tab title="Call Order Data" active>
                            <OrderData side="CALL" />
                        </b-tab>
                        <b-tab title="Put Order Data">
                            <OrderData side="PUT" />
                        </b-tab>
                        <b-tab title="Future Order Data">
                            <div class="w-100" style="margin-top: 25px; margin-bottom: 35px">
                                <div class="w-100 d-flex align-items-center justify-content-end" style="margin-bottom: 25px;">
                                    <div class="d-flex align-items-center" style="margin-left: 15px;">
                                        <span style="margin-right: 10px;">Show Future Order Details :</span>
                                        <label class="switch">
                                            <input type="checkbox" v-model="toggleFutureTable">
                                            <span class="slider"></span>
                                        </label>
                                    </div>
                                </div>
            
                                <table class="table table-bordered table-striped table-hover table-responsive">
                                    <thead>
                                        <tr>
                                            <td colspan="8" style="text-align: center; font-weight: bold;">
                                                <h5>{{ this.toggleFutureTable ? 'Future Order Details'
                                                    : 'Order Details' }}</h5>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>{{ this.toggleFutureTable ? 'Select Expiry Date' : 'Expiry Date'
                                                    }}</strong>
                                            </td>
                                            <td><strong>{{ this.toggleFutureTable ? 'Select Call Strike' : 'Call Strike'
                                                    }}</strong>
                                            </td>
                                            <td><strong>Select Order Type</strong></td>
                                            <td><strong>Quantity</strong></td>
                                            <td><strong>Trail Stop Loss</strong></td>
                                            <td><strong>{{ this.toggleFutureTable ? 'Select Put Strike' : 'Put Strike'
                                                    }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="!toggleFutureTable">
                                                {{ this.selectedExpiryDate }}
                                            </td>
                                            <td v-if="toggleFutureTable">
                                                <input type="date" v-model="selectedExpiryDate" id="date-select-expiry"
                                                    @change="handleExpiryDate">
                                            </td>
                                            <td v-if="!toggleFutureTable">{{ this.latestData?.call || 0 }}</td>
                                            <td v-if="toggleFutureTable">
                                                <div class="mx-2">
                                                    <!-- :fetchNewData="() => this.fetchFutureData(true, 'call')"  -->
                                                    <CustomSelect :options="this.futureCallDataLimits.map((data) => data)"
                                                        v-model="this.selectedFutureCallStrike" @change="handleFutureCallChange"
                                                        :fetchNewData="() => console.log('new data fetch called successfully for call')" />
                                                </div>
                                            </td>
            
                                            <td>
                                                <div class="mx-2">
                                                    <select v-model="selectedOrderType">
                                                        <option v-for="orderType in orderTypeLimits" :key="orderType"
                                                            :value="orderType">
                                                            {{ orderType }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="mx-2">
                                                    <label for="number-select" style="margin-right: 5px">Select a size :</label>
                                                    <select v-model="selectedOrderSize" id="number-select">
                                                        <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                                            {{ number }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="this.selectedOrderType === 'TRAIL_VALUE'" class="mx-2">
                                                    <label for="trail-type-value" style="margin-right: 5px">Select a Put Trail
                                                        Value
                                                        :</label>
                                                    <select v-model="selectedTrailValue" id="trail-type-value">
                                                        <option v-for="value in trailValue" :key="value" :value="value">
                                                            {{ value }}
                                                        </option>
                                                    </select>
                                                </div>
            
                                                <div v-if="this.selectedOrderType === 'TRAIL_PERCENTAGE'" class="mx-2">
                                                    <label for="trail-type" style="margin-right: 5px">Select a Trail Percentage
                                                        :</label>
                                                    <select v-model="selectedTrailPercentage" id="trail-type">
                                                        <option v-for="percentage in trailPercentage" :key="percentage"
                                                            :value="percentage">
                                                            {{ percentage }}
                                                        </option>
                                                    </select>
                                                </div>
            
                                                <div v-if="this.selectedOrderType === 'MKT'">N/A</div>
                                            </td>
                                            <td v-if="!toggleFutureTable">{{ this.latestData?.put || 0 }}</td>
                                            <td v-if="toggleFutureTable">
                                                <div class="mx-2">
                                                    <!-- :fetchNewData="() => this.fetchFutureData(true, 'put')"  -->
                                                    <CustomSelect :options="this.futurePutDataLimits.map((data) => data)"
                                                        v-model="this.selectedFuturePutStrike" @change="handleFuturePutChange"
                                                        :fetchNewData="() => console.log('new data fetch called successfully for put')" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
            
                                <div class="w-100 mb-4 d-flex align-items-center justify-content-between">
                                    <div>
                                        <div class="d-flex align-items-center">
                                            <p>
                                                Put Strike: <b>{{ this.toggleFutureTable ? this.selectedFutureCallStrike :
                                                    this.latestData?.call || 0 }}</b>
                                            </p>
                                            <p style="margin-right: 10px; margin-left: 10px;">
                                                Ask Price: <b>{{ this.toggleFutureTable ? this.selectedFutureCallAskPrice || 0 :
                                                    this.latestData?.callAskPrice || 0 }}</b>
                                            </p>
                                            <p>
                                                Bid Price: <b>{{ this.toggleFutureTable ? this.selectedFutureCallBidPrice || 0 :
                                                    this.latestData?.callBidPrice || 0 }}</b>
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <b-button :disabled="!ibkrServerStatus || this.latestData === null" class="btn-success"
                                                @click="() => {
                                                    if (this.selectedOrderPutType === 'TRAIL_VALUE') {
                                                        handleBuyOrder(
                                                            this.selectedOrderSize,
                                                            'TRAIL_VALUE',
                                                            this.toggleFutureTable ? this.selectedFutureCallAskPrice : Number(this.latestData?.callAskPrice),
                                                            Number(this.selectedTrailValue),
                                                            this.toggleFutureTable ? this.selectedTrailPercentage : this.selectedCallTrailPercentage,
                                                            this.toggleFutureTable ? this.selectedFutureCallStrike : this.latestData?.call,
                                                            this.latestData?.baseValue,
                                                            this.toggleFutureTable ? this.selectedExpiryDate : this.latestData?.date,
                                                            'P',
                                                            true
                                                        )
                                                    } else {
                                                        handleBuyOrder(
                                                            this.selectedOrderSize,
                                                            'MKT',
                                                            this.toggleFutureTable ? this.selectedFutureCallAskPrice : Number(this.latestData?.callAskPrice),
                                                            this.toggleFutureTable ? this.selectedFutureCallAskPrice : Number(this.latestData?.callAskPrice),
                                                            this.toggleFutureTable ? this.selectedTrailPercentage : this.selectedCallTrailPercentage,
                                                            this.toggleFutureTable ? this.selectedFutureCallStrike : this.latestData?.call,
                                                            this.latestData?.baseValue,
                                                            this.toggleFutureTable ? this.selectedExpiryDate : this.latestData?.date,
                                                            'P',
                                                            true
                                                        )
                                                    }
                                                }">Buy</b-button>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end justify-content-center">
                                        <div class="d-flex align-items-center">
                                            <p>
                                                Call Strike: <b>{{ this.toggleFutureTable ? this.selectedFuturePutStrike :
                                                    this.latestData?.put || 0 }}</b>
                                            </p>
                                            <p style="margin-right: 10px; margin-left: 10px;">
                                                Ask Price: <b>{{ this.toggleFutureTable ? this.selectedFuturePutAskPrice || 0 :
                                                    this.latestData?.putAskPrice || 0 }}</b>
                                            </p>
                                            <p>
                                                Bid Price: <b>{{ this.toggleFutureTable ? this.selectedFuturePutBidPrice || 0 :
                                                    this.latestData?.putBidPrice || 0 }}</b>
                                            </p>
                                        </div>
            
                                        <div class="d-flex align-items-center">
                                            <b-button :disabled="!ibkrServerStatus || this.latestData === null" class="btn-success"
                                                @click="() => {
                                                    if (this.selectedOrderPutType === 'TRAIL_VALUE') {
                                                        handleBuyOrder(
                                                            this.selectedOrderSize,
                                                            'TRAIL_VALUE',
                                                            this.toggleFutureTable ? this.selectedFuturePutAskPrice : Number(this.latestData?.putAskPrice),
                                                            Number(this.selectedTrailValue),
                                                            this.toggleFutureTable ? this.selectedTrailPercentage : this.selectedPutTrailPercentage,
                                                            this.toggleFutureTable ? this.selectedFuturePutStrike : this.latestData?.put,
                                                            this.latestData?.baseValue,
                                                            this.toggleFutureTable ? this.selectedExpiryDate : this.latestData?.date,
                                                            'C',
                                                            true
                                                        )
                                                    } else {
                                                        handleBuyOrder(
                                                            this.selectedOrderSize,
                                                            'MKT',
                                                            this.toggleFutureTable ? this.selectedFuturePutAskPrice : Number(this.latestData?.putAskPrice),
                                                            this.toggleFutureTable ? this.selectedFuturePutAskPrice : Number(this.latestData?.putAskPrice),
                                                            this.toggleFutureTable ? this.selectedTrailPercentage : this.selectedPutTrailPercentage,
                                                            this.toggleFutureTable ? this.selectedFuturePutStrike : this.latestData?.put,
                                                            this.latestData?.baseValue,
                                                            this.toggleFutureTable ? this.selectedExpiryDate : this.latestData?.date,
                                                            'C',
                                                            true
                                                        )
                                                    }
                                                }">Buy</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import VueCookie from 'vue-cookie';
import moment from 'moment-timezone';
import AppHeader from '@/components/AppHeader.vue';
import DataTable from '@/components/DataTable.vue';
import OrderData from '@/components/OrderData.vue';
// import ScheduleOrderData from '@/components/ScheduleOrderData.vue';
import CustomSelect from '@/components/CustomSelect.vue';
import reconnectMainSocket, { disconnectSockets, reconnectSecondarySocket } from '@/plugins/socket';
import { buyOrder } from '@/plugins/order';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

export default {
    components: {
        AppHeader,
        DataTable,
        OrderData,
        // ScheduleOrderData,
        CustomSelect
    },
    data() {
        return {
            role: '',
            // IBKR status
            ibkrClientStatus: false,
            ibkrServerStatus: false,
            spinning: false,
            // recurring sell status
            recurringScheduleOrderStatus: false,
            // Holiday check
            isHoliday: false,
            holidayStatus: 'closed',
            holidayReason: 'holiday',
            // Settings
            isSettingLoading: false, 
            setting: {
                callOrderSize: 1,
                putOrderSize: 1,
                selectedRecurringBuyCallDuration: 1,
                stopRecurringBuyCallOrder: false,
                isPlacingCallOrder: false,
                selectedRecurringSellCallDuration: 1,
                selectedRecurringBuyPutDuration: 1,
                stopRecurringBuyPutOrder: false,
                isPlacingPutOrder: false,
                selectedRecurringSellPutDuration: 1,
                scheduleOrder: false,
                ibkrOrder: false
            },
            // Polygon Data
            latestData: null,
            // Select Order Size
            orderSizeLimits: this.generateNumbers(0, 20, 1),
            selectedOrderSize: 1,
            // Select Order Type
            orderTypeLimits: ['MKT', 'TRAIL_VALUE'],
            selectedOrderCallType: 'MKT',
            selectedOrderPutType: 'MKT',
            selectedOrderType: 'MKT',
            // Select Order Strike
            selectedCallStrike: 0,
            selectedPutStrike: 0,
            selectedFutureStrike: 0,
            // Select Order Trailing Percentage
            trailPercentage: this.generateFloatNumbers(),
            trailValue: this.generateFloatNumbers(),
            selectedCallTrailPercentage: 10,
            selectedPutTrailPercentage: 10,
            selectedTrailPercentage: 10,
            selectedCallTrailValue: 10,
            selectedPutTrailValue: 10,
            selectedTrailValue: 10,
            // Order Future Data
            toggleFutureTable: false,
            selectedExpiryDate: moment().format('YYYY-MM-DD'),
            futureCallDataLimits: [],
            selectedFutureCallStrike: 0,
            selectedFutureCallAskPrice: 0,
            selectedFutureCallBidPrice: 0,
            futureCallNextUrl: null,
            futurePutDataLimits: [],
            selectedFuturePutStrike: 0,
            selectedFuturePutAskPrice: 0,
            selectedFuturePutBidPrice: 0,
            futurePutNextUrl: null,
            // Recurring Data
            durationArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 45, 60, 90, 120],
            // Portfolio Data
            accountSummary: {},
            positionData: [],
            // Data Table Data
            putLimits: this.generateLimits(),
            selectedPutLimit: '0.00',
            callLimits: this.generateLimits(),
            selectedCallLimit: '0.00',
            // Sorting Filed
            sortFields: {
                date: false,
                position: false,
                dailyPnL: false,
                unrealizedPnL: false,
                realizedPnL: false
            },
        }
    },
    mounted() {
        this.role = VueCookie.get('userRole') || 'user'
        this.fetchFutureData(false, 'all')

        if (VueCookie.get('authStatus') !== "true") {
            this.$router.push('/')
        }

        if (VueCookie.get('authStatus') === "true") {
            console.log("cookie data", VueCookie.get('allowedIndex').split(','), VueCookie.get('allowedIndex').split(',').includes('spx'))
            if (VueCookie.get('allowedIndex').split(',').includes('spx')) {
                console.log("socket details", this.socketMain, this.socketSecondary)
                if (this.socketMain && this.socketSecondary) {
                    this.socketMain.disconnect();
                    this.socketSecondary.disconnect();
                    console.log('Socket.IO disconnected due to authStatus change');
                } else {
                    this.connectSocketIO();
                    this.connectSocketSecondaryIo()
                }
            } else {
                $toast.open({
                    message: "You're Not Allowed For This Index",
                    type: 'default',
                    duration: 3000,
                    dismissible: true,
                    queue: true,
                });
            }
        }
    },
    methods: {
        formatBaseValue() {
            return parseFloat(this.latestData?.baseValue).toFixed(2) ?? 0;
        },

        showToast(message, type) {
            $toast.open({
                message,
                type,
                duration: 3000,
                dismissible: true,
                queue: true,
            });
        },

        generateLimits() {
            return Array.from({ length: 21 }, (_, i) => (i * 0.5).toFixed(2));
        },

        generateNumbers(start = 0, end, inc) {
            let numbers = [];
            for (let i = start; i <= end; i += inc) {
                numbers.push(i);
            }
            return numbers;
        },

        generateFloatNumbers() {
            let numbers = [];

            for (let i = 1; i <= 10; i += 1) {
                numbers.push(i);
            }

            for (let i = 20; i <= 100; i += 10) {
                numbers.push(i);
            }

            return numbers;
        },

        convertOptionSymbol(symbol) {
            const match = symbol.match(/SPXW\s*(\d{6})([CP])(\d{8})/);
            if (!match) return "Invalid Symbol";

            const [, date, type, strike] = match;

            // Convert date to readable format
            const year = "20" + date.slice(0, 2);
            const month = date.slice(2, 4);
            const day = date.slice(4, 6);
            const formattedDate = new Date(`${year}-${month}-${day}`);

            const monthAbbrev = formattedDate.toLocaleString('en-US', { month: 'short' });
            const finalDate = `${monthAbbrev}${day}'${year.slice(2)}`;

            // Format strike price
            const formattedStrike = (parseInt(strike, 10) / 1000).toFixed(2).replace(/\.00$/, '');

            // Determine type
            const optionType = type === 'C' ? 'CALL' : 'PUT';

            return `SPX (SPXW) ${finalDate} ${formattedStrike} ${optionType}`;
        },

        async logout() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/logout`;
            try {
                // Send the reconnect request
                const response = await fetch(url, {
                    method: 'GET', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                });

                // Check for a valid response
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the JSON response
                const result = await response.json();

                // Handle response based on status
                if (result) {
                    VueCookie.delete('authToken')
                    VueCookie.set('authStatus')
                    disconnectSockets()
                    this.$router.push('/')
                }
            } catch (error) {
                console.error("Error in triggerSpin:", error.message);
                this.showToast(
                    "Failed to send reconnect request.",
                    "error"
                );
            }
        },

        async triggerSpin() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/reconnect`;
            this.spinning = true;

            try {
                // Send the reconnect request
                const response = await fetch(url, {
                    method: 'GET', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                });

                // Check for a valid response
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the JSON response
                const result = await response.json();

                // Handle response based on status
                if (result.status === 200) {
                    this.showToast("Reconnect Request Sent Successfully!", "success");
                }
            } catch (error) {
                console.error("Error in triggerSpin:", error.message);
                this.showToast(
                    "Failed to send reconnect request.",
                    "error"
                );
            } finally {
                // Stop spinning regardless of success or failure
                setTimeout(() => {
                    this.spinning = false;
                }, 1500)
            }
        },

        async toggleIbkrOrder(value) {
            console.log("toggleIbkrOrder", this.setting.ibkrOrder, value)
            if (this.setting.ibkrOrder !== value) {
                this.setting.ibkrOrder = value
                await this.updateSettings()
            }
        },

        async disconnectIbkr() {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/disconnect`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error disconnection ibkr:`, error);
            }
        },

        savePutLimit(limit) {
            this.selectedPutLimit = limit;
            this.socketMain && this.socketMain.emit('changePutLimit', limit);
        },

        saveCallLimit(limit) {
            this.selectedCallLimit = limit;
            this.socketMain && this.socketMain.emit('changeCallLimit', limit);
        },

        async saveIsPlacingCallOrder(value) {
            if (this.setting.isPlacingCallOrder !== value) {
                this.isSettingLoading = true
                this.setting.isPlacingCallOrder = value
                await this.updateSettings()
            }
        },

        async saveIsPlacingPutOrder(value) {
            if (this.setting.isPlacingPutOrder !== value) {
                this.isSettingLoading = true
                this.setting.isPlacingPutOrder = value
                await this.updateSettings()
            }
        },

        async saveSelectedCallOrderSize(orderSize) {
            if (this.setting.callOrderSize !== orderSize) {
                this.isSettingLoading = true
                this.setting.callOrderSize = orderSize
                await this.updateSettings()
            }
        },

        async saveSelectedPutOrderSize(orderSize) {
            if (this.setting.putOrderSize !== orderSize) {
                this.isSettingLoading = true
                this.setting.putOrderSize = orderSize
                await this.updateSettings()
            }
        },

        async saveSelectedCallDuration(duration) {
            if (this.setting.selectedRecurringBuyCallDuration !== duration) {
                if (duration === '0') {
                    this.setting.stopRecurringBuyCallOrder = true
                } else {
                    this.setting.stopRecurringBuyCallOrder = false
                }

                this.setting.selectedRecurringBuyCallDuration = duration
                this.isSettingLoading = true
                await this.updateSettings()
            }
        },

        async saveSelectedPutDuration(duration) {
            if (this.setting.selectedRecurringBuyPutDuration !== duration) {
                if (duration === '0') {
                    this.setting.stopRecurringBuyPutOrder = true
                } else {
                    this.setting.stopRecurringBuyPutOrder = false
                }

                this.setting.selectedRecurringBuyPutDuration = duration
                this.isSettingLoading = true
                await this.updateSettings()
            }
        },

        async saveRecurringSellPutDuration(value) {
            if (this.setting.selectedRecurringSellPutDuration !== value) {
                this.setting.selectedRecurringSellPutDuration = value
                this.isSettingLoading = true
                await this.updateSettings()
            }
        },

        async saveRecurringSellCallDuration(value) {
            if (this.setting.selectedRecurringSellCallDuration !== value) {
                this.setting.selectedRecurringSellCallDuration = value
                this.isSettingLoading = true
                await this.updateSettings()
            }
        },

        async executeOrder(action, orderDetails) {
            console.log('OrderDetails right ' + orderDetails?.right + " ", action, orderDetails)

            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/${action}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(orderDetails),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                await response.json();
                // this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing ${action} order:`, error);
                this.showToast(`Error placing ${action} order: ${error.message}`, 'error');
            }
        },

        async executeRecurringOrder(orderDetails) {
            console.log('OrderDetails right ' + orderDetails?.right + " ", orderDetails)

            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/recurringOrder`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify({ ...orderDetails, isRecurringOrder: true }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                await response.json();
                // this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing order:`, error);
                this.showToast(`Error placing order: ${error.message}`, 'error');
            }
        },

        getOrderDetails(right, orderType, isNormalOrder) {
            const isPut = right === 'PUT';
            const isFutureTable = this.toggleFutureTable;
            const isTrailOrder = orderType === 'TRAIL_VALUE'

            console.log('orderDetails', right, orderType, isTrailOrder)
            console.log('orderDetails quantity', isNormalOrder, this.selectedOrderSize, this.setting.callOrderSize, this.setting.putOrderSize)

            return {
                symbol: 'SPX',
                quantity: isPut
                    ? !isNormalOrder ? this.setting.putOrderSize : this.selectedOrderSize
                    : !isNormalOrder ? this.setting.callOrderSize : this.selectedOrderSize,
                orderType,
                lmtPrice: isPut
                    ? isFutureTable
                        ? !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : this.selectedFuturePutAskPrice
                        : !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : Number(this.latestData?.putAskPrice)
                    : isFutureTable
                        ? !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : this.selectedFutureCallAskPrice
                        : !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : Number(this.latestData?.callAskPrice),
                auxPrice: isPut
                    ? isFutureTable
                        ? isTrailOrder ? !isNormalOrder ? Number(this.selectedPutTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : this.selectedFuturePutAskPrice
                        : isTrailOrder ? !isNormalOrder ? Number(this.selectedPutTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : Number(this.latestData?.putAskPrice)
                    : isFutureTable
                        ? isTrailOrder ? !isNormalOrder ? Number(this.selectedCallTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : this.selectedFutureCallAskPrice
                        : isTrailOrder ? !isNormalOrder ? Number(this.selectedCallTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : Number(this.latestData?.callAskPrice),
                trailingPercent: isPut
                    ? isFutureTable
                        ? this.selectedTrailPercentage
                        : this.selectedPutTrailPercentage
                    : isFutureTable
                        ? this.selectedTrailPercentage
                        : this.selectedCallTrailPercentage,
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: 'OPT',
                exchange: 'SMART',
                strike: isPut
                    ? isFutureTable
                        ? !isNormalOrder ? this.selectedPutStrike : this.selectedFuturePutStrike
                        : !isNormalOrder ? this.selectedPutStrike : this.latestData?.put || 0
                    : isFutureTable
                        ? !isNormalOrder ? this.selectedCallStrike : this.selectedFutureCallStrike
                        : !isNormalOrder ? this.selectedCallStrike : this.latestData?.call || 0,
                strikeBaseValue: this.latestData?.baseValue,
                expiry: isFutureTable
                    ? moment(this.selectedExpiryDate, 'YYYY/MM/DD').format('YYYYMMDD')
                    : moment(this.latestData?.date, 'MM/DD/YYYY').format('YYYYMMDD'),
                right: right === 'CALL' ? 'P' : 'C',
            };
        },

        async placeOrder(action, right, orderType, isNormalOrder, isRecurring = false) {
            const orderDetails = this.getOrderDetails(right, orderType, isNormalOrder);

            if (isRecurring) {
                await this.executeOrder(action, { ...orderDetails, isRecurringOrder: true });
            } else {
                await this.executeOrder(action, orderDetails);
            }
        },

        async handleBuyOrder(quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right, isFutureOrder = false) {
            const now = new Date();
            const formattedUTCDate = now.toISOString().replace('Z', '+00:00');

            if (orderType === 'MKT' && !isFutureOrder) {
                if (right === 'P' && this.setting.isPlacingPutOrder || right === 'C' && this.setting.isPlacingCallOrder) {
                    if (this.setting.isPlacingPutOrder) {
                        this.saveIsPlacingPutOrder(false)
                    } else {
                        this.saveIsPlacingCallOrder(false)
                    }

                    await this.executeBulkSellOrder(right === "C" ? "P" : "C")

                    await this.executeRecurringOrder({ right, recurringStatus: false, recurringEndTime: formattedUTCDate })
                } else {
                    await this.executeBulkSellOrder(right === "C" ? "P" : "C")
                }
            }

            await buyOrder(quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, this.toggleFutureTable ? moment(expiry, 'YYYY/MM/DD').format('YYYYMMDD') : moment(expiry, 'MM/DD/YYYY').format('YYYYMMDD'), right)
        },

        async executeBulkSellOrder(right) {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/bulkSell`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify({ right, strikeBaseValue: this.latestData?.baseValue }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                await response.json();
                // this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing ${right} order:`, error);
                this.showToast(`Error placing ${right} order: ${error.message}`, 'error');
            }
        },

        async recurringOrder(right, orderType, isNormalOrder, selectedDuration) {
            const now = new Date();
            const formattedUTCDate = now.toISOString().replace('Z', '+00:00');
            const { auxPrice, lmtPrice, trailingPercent, ...orderDetails } = this.getOrderDetails(right, orderType, isNormalOrder);

            console.log('not required fields', auxPrice, lmtPrice, trailingPercent)

            if (right === "CALL") {
                if (this.setting.isPlacingPutOrder) {
                    this.saveIsPlacingPutOrder(false)
                }

                this.saveIsPlacingCallOrder(!this.setting.isPlacingCallOrder)

                if (this.setting.isPlacingCallOrder) {
                    await this.placeOrder('buy', right, orderType, isNormalOrder, true);
                    await this.executeBulkSellOrder('C')
                    await this.executeRecurringOrder({ ...orderDetails, orderAction: 'BUY', recurringStatus: true, recurringTime: selectedDuration, recurringStartTime: formattedUTCDate })
                } else {
                    await this.executeBulkSellOrder('P')
                    await this.executeRecurringOrder({ right: orderDetails?.right, recurringStatus: false, recurringEndTime: formattedUTCDate })
                }
            } else {
                if (this.setting.isPlacingCallOrder) {
                    this.saveIsPlacingCallOrder(false)
                }

                this.saveIsPlacingPutOrder(!this.setting.isPlacingPutOrder)

                if (this.setting.isPlacingPutOrder) {
                    await this.placeOrder('buy', right, orderType, isNormalOrder, true);
                    await this.executeBulkSellOrder('P')
                    await this.executeRecurringOrder({ ...orderDetails, orderAction: 'BUY', recurringStatus: true, recurringTime: selectedDuration, recurringStartTime: formattedUTCDate })
                } else {
                    await this.executeBulkSellOrder('C')
                    await this.executeRecurringOrder({ right: orderDetails?.right, recurringStatus: false, recurringEndTime: formattedUTCDate })
                }
            }
        },

        async fetchFutureData(isNext, type, date = moment().format('YYYY-MM-DD')) {
            try {
                let url = `${process.env.VUE_APP_BACKEND_URL}/api/getStrikeData?date=${date}`

                // console.log("fetchFutureData is called", isNext, type)

                if (isNext) {
                    if (type === 'call' && this.futureCallNextUrl) {
                        url = `${process.env.VUE_APP_BACKEND_URL}/api/getStrikeData?url=${this.futureCallNextUrl}`
                    } else if (type === 'put' && this.futurePutNextUrl) {
                        url = `${process.env.VUE_APP_BACKEND_URL}/api/getStrikeData?url=${this.futurePutNextUrl}`
                    } else {
                        return
                    }
                }

                const resp = await fetch(url);

                if (!resp.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await resp.json();


                if (isNext) {
                    if (type === 'call') {
                        // console.log("fetchFutureData Call", result)
                        const modifiedCallData = [...this.futureCallDataLimits, ...result?.data?.call?.data]
                        this.futureCallNextUrl = result?.data?.call?.next_url
                        this.futureCallDataLimits = modifiedCallData
                    } else if (type === 'put') {
                        // console.log("fetchFutureData Put", result)
                        const modifiedPutData = [...this.futurePutDataLimits, ...result?.data?.put?.data]
                        this.futurePutNextUrl = result?.data?.put?.next_url
                        this.futurePutDataLimits = modifiedPutData
                    } else {
                        return
                    }
                } else {
                    // console.log("fetchFutureData Call", result?.data?.call?.data)
                    this.futureCallDataLimits = result?.data?.call?.data;
                    this.futureCallNextUrl = result?.data?.call?.next_url
                    this.selectedFutureCallStrike = 0
                    this.selectedFutureCallAskPrice = 0
                    this.selectedFutureCallBidPrice = 0
                    // console.log("fetchFutureData Put", result?.data?.put?.data)
                    this.futurePutDataLimits = result?.data?.put?.data
                    this.futurePutNextUrl = result?.data?.put?.next_url
                    this.selectedFuturePutStrike = 0
                    this.selectedFuturePutAskPrice = 0
                    this.selectedFuturePutBidPrice = 0
                }
            } catch (err) {
                this.error = err.message;  // Store any error message
            }
        },

        handleExpiryDate() {
            this.selectedExpiryDate !== "" && this.fetchFutureData(false, 'all', this.selectedExpiryDate)
        },

        handleFutureCallChange(value) {
            // console.log("fetchFutureData", value)
            this.selectedFutureCallStrike = value?.strike_price
            this.selectedFutureCallAskPrice = value?.askPrice
            this.selectedFutureCallBidPrice = value?.bidPrice
        },

        handleFuturePutChange(value) {
            // console.log("fetchFutureData", value)
            this.selectedFuturePutStrike = value?.strike_price
            this.selectedFuturePutAskPrice = value?.askPrice
            this.selectedFuturePutBidPrice = value?.bidPrice
        },

        async updateSettings() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/setting`;
            const settingData = this.setting

            try {
                const response = await fetch(url, {
                    method: 'POST', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(settingData), // Convert the data to JSON string
                });

                if (response.ok) {
                    await response.json();
                } else {
                    console.error('Failed to update setting:', response.status);
                }

                this.isSettingLoading = false
            } catch (error) {
                console.error('Error during updating setting request:', error);
            }
        },

        sortData(key) {
            if (this.positionData.length > 0) {
                if (key === 'position') {
                    this.sortFields.position = !this.sortFields.position;

                    if (this.sortFields.position) {
                        this.sortFields.dailyPnL = false
                        this.sortFields.unrealizedPnL = false
                        this.sortFields.realizedPnL = false
                        this.sortFields.date = false
                    }

                    // Sort the array in place
                    this.positionData = [...this.positionData].sort((a, b) => {
                        if (a.position === undefined || b.position === undefined) {
                            return 0; // Handle undefined values if necessary
                        }

                        if (!this.sortFields.position) {
                            return a.position - b.position;  // Ascending
                        } else {
                            return b.position - a.position;  // Descending
                        }
                    });
                } else if (key === 'dailyPnL') {
                    this.sortFields.dailyPnL = !this.sortFields.dailyPnL;

                    if (this.sortFields.dailyPnL) {
                        this.sortFields.position = false
                        this.sortFields.unrealizedPnL = false
                        this.sortFields.realizedPnL = false
                        this.sortFields.date = false
                    }

                    this.positionData = [...this.positionData].sort((a, b) => {
                        if (a.dailyPnL === undefined || b.dailyPnL === undefined) {
                            return 0; // Handle undefined values if necessary
                        }

                        if (!this.sortFields.dailyPnL) {
                            return a.dailyPnL - b.dailyPnL;  // Ascending
                        } else {
                            return b.dailyPnL - a.dailyPnL;  // Descending
                        }
                    })
                } else if (key === 'unrealizedPnL') {
                    this.sortFields.unrealizedPnL = !this.sortFields.unrealizedPnL;

                    if (this.sortFields.unrealizedPnL) {
                        this.sortFields.position = false
                        this.sortFields.dailyPnL = false
                        this.sortFields.realizedPnL = false
                        this.sortFields.date = false
                    }

                    this.positionData = [...this.positionData].sort((a, b) => {
                        if (a.unrealizedPnL === undefined || b.unrealizedPnL === undefined) {
                            return 0; // Handle undefined values if necessary
                        }

                        if (!this.sortFields.unrealizedPnL) {
                            return a.unrealizedPnL - b.unrealizedPnL;  // Ascending
                        } else {
                            return b.unrealizedPnL - a.unrealizedPnL;  // Descending
                        }
                    })
                } else if (key === 'realizedPnL') {
                    this.sortFields.realizedPnL = !this.sortFields.realizedPnL;

                    if (this.sortFields.realizedPnL) {
                        this.sortFields.position = false
                        this.sortFields.dailyPnL = false
                        this.sortFields.unrealizedPnL = false
                        this.sortFields.date = false
                    }

                    this.positionData = [...this.positionData].sort((a, b) => {
                        if (a.realizedPnL === undefined || b.realizedPnL === undefined) {
                            return 0; // Handle undefined values if necessary
                        }

                        if (!this.sortFields.realizedPnL) {
                            return a.realizedPnL - b.realizedPnL;  // Ascending
                        } else {
                            return b.realizedPnL - a.realizedPnL;  // Descending
                        }
                    })
                } else if (key === 'date') {
                    this.sortFields.date = !this.sortFields.date;

                    if (this.sortFields.date) {
                        this.sortFields.position = false
                        this.sortFields.dailyPnL = false
                        this.sortFields.unrealizedPnL = false
                        this.sortFields.realizedPnL = false
                    }
                }
            }
        },

        connectSocketIO() {
            this.socketMain = reconnectMainSocket()

            if (!this.socketMain.connected) {
                console.log("Forcing socket connection...");
                this.socketMain.connect();
            }

            this.socketMain.on('connect', () => {
                console.log('socket details socketMain connection established.');
                this.socketMain.emit('message', 'Hello server, send me polyData!');
            });

            this.socketMain.on('accountSummary', data => {
                this.accountSummary = data
            })

            this.socketMain.on('position', data => {
                console.log('position data:', data);

                if (data.length > 0) {
                    const filterData = data.filter((singleData) => singleData.position !== 0)

                    if (filterData.length > 1) {
                        if (this.sortFields.position) {
                            this.positionData = filterData.sort((a, b) => { return b.position - a.position })
                        } else if (this.sortFields.dailyPnL) {
                            this.positionData = filterData.sort((a, b) => { return b.dailyPnL - a.dailyPnL })
                        } else if (this.sortFields.unrealizedPnL) {
                            this.positionData = filterData.sort((a, b) => { return b.unrealizedPnL - a.unrealizedPnL })
                        } else if (this.sortFields.realizedPnL) {
                            this.positionData = filterData.sort((a, b) => { return b.realizedPnL - a.realizedPnL })
                        } else if (this.sortFields.date) {
                            this.positionData = this.sortFields.date ? filterData.reverse() : filterData
                        } else {
                            this.positionData = filterData
                        }
                    } else {
                        this.positionData = filterData
                    }
                }
            })

            this.socketMain.on('holiday', data => {
                this.isHoliday = data?.isHoliday
                this.holidayStatus = data?.holidayStatus
                this.holidayReason = data?.holidayReason
            })

            // this.socketMain.on('orderStatus', data => {
            //     if (VueCookie.get('authStatus') === "true" && data?.orderId) {
            //         let message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus}`

            //         switch (data?.orderStatus) {
            //             case 'Cancelled':
            //                 this.showToast(message, 'error')
            //                 break;
            //             case 'Pending':
            //                 this.showToast(message, 'info')
            //                 break;
            //             case 'Filled':
            //                 message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus} with ${data?.avgFillPrice}`
            //                 this.showToast(message, 'success')
            //                 break;
            //             case 'Inactive':
            //                 this.showToast(message, 'error')
            //                 break;
            //             case 'PreSubmitted':
            //                 this.showToast(message, 'info')
            //                 break;
            //             case 'Submitted':
            //                 this.showToast(message, 'success')
            //                 break;
            //         }
            //     }
            // })

            this.socketMain.on('orderError', data => {
                if (VueCookie.get('authStatus') === "true") {
                    this.showToast(data, 'error')
                }
            })

            this.socketMain.on('ibkrStatus', data => {
                if (this.ibkrClientStatus !== data?.ibkrClientStatus) {
                    this.ibkrClientStatus = data?.ibkrClientStatus
                }

                if (this.ibkrServerStatus !== data?.ibkrServerStatus) {
                    this.ibkrServerStatus = data?.ibkrServerStatus
                }
            })

            this.socketMain.on('recurringScheduleOrder', data => {
                if (this.recurringScheduleOrderStatus !== data) {
                    this.recurringScheduleOrderStatus = data
                }
            })

            this.socketMain.on('settings', data => {
                this.setting.callOrderSize = data.callOrderSize
                this.setting.putOrderSize = data.putOrderSize
                this.setting.selectedRecurringBuyCallDuration = data.selectedRecurringBuyCallDuration
                this.setting.stopRecurringBuyCallOrder = data.stopRecurringBuyCallOrder
                this.setting.isPlacingCallOrder = data.isPlacingCallOrder
                this.setting.selectedRecurringSellCallDuration = data.selectedRecurringSellCallDuration
                this.setting.selectedRecurringBuyPutDuration = data.selectedRecurringBuyPutDuration
                this.setting.stopRecurringBuyPutOrder = data.stopRecurringBuyPutOrder
                this.setting.isPlacingPutOrder = data.isPlacingPutOrder
                this.setting.selectedRecurringSellPutDuration = data.selectedRecurringSellPutDuration
                this.setting.scheduleOrder = data.scheduleOrder
                this.setting.ibkrOrder = data.ibkrOrder

                VueCookie.set('callOrderSize', data.callOrderSize, { expires: '12h' })
                VueCookie.set('putOrderSize', data.putOrderSize, { expires: '12h' })
                VueCookie.set('selectedRecurringBuyCallDuration', data.selectedRecurringBuyCallDuration, { expires: '12h' })
                VueCookie.set('stopRecurringBuyCallOrder', data.stopRecurringBuyCallOrder, { expires: '12h' })
                VueCookie.set('isPlacingCallOrder', data.isPlacingCallOrder, { expires: '12h' })
                VueCookie.set('selectedRecurringSellCallDuration', data.selectedRecurringSellCallDuration, { expires: '12h' })
                VueCookie.set('selectedRecurringBuyPutDuration', data.selectedRecurringBuyPutDuration, { expires: '12h' })
                VueCookie.set('stopRecurringBuyPutOrder', data.stopRecurringBuyPutOrder, { expires: '12h' })
                VueCookie.set('isPlacingPutOrder', data.isPlacingPutOrder, { expires: '12h' })
                VueCookie.set('selectedRecurringSellPutDuration', data.selectedRecurringSellPutDuration, { expires: '12h' })
                VueCookie.set('scheduleOrder', data.scheduleOrder, { expires: '12h' })
                VueCookie.set('ibkrOrder', data.ibkrOrder, { expires: '12h' })
            })

            setInterval(() => {
                if (this.toggleFutureTable && this.selectedFutureCallStrike !== 0 && this.selectedFutureCallAskPrice !== undefined) {
                    this.socketMain.emit('getStrikeDataByStrikeValue', { 'date': this.selectedExpiryDate, 'contract_type': 'call', clientStrikePrice: this.selectedFutureCallStrike }, (response) => {
                        const strikeData = response?.data;

                        if (!strikeData) {
                            console.error("No strike data found in response");
                            return;
                        }

                        this.selectedFutureCallAskPrice = strikeData?.askPrice
                        this.selectedFutureCallBidPrice = strikeData?.bidPrice
                    })
                }
            }, 1000)

            setInterval(() => {
                if (this.toggleFutureTable && this.selectedFuturePutStrike !== 0 && this.selectedFuturePutStrike !== undefined) {
                    this.socketMain.emit('getStrikeDataByStrikeValue', { 'date': this.selectedExpiryDate, 'contract_type': 'put', clientStrikePrice: this.selectedFuturePutStrike }, (response) => {
                        const strikeData = response?.data;

                        if (!strikeData) {
                            console.error("No strike data found in response");
                            return;
                        }

                        this.selectedFuturePutAskPrice = strikeData?.askPrice
                        this.selectedFuturePutBidPrice = strikeData?.bidPrice
                    })
                }
            }, 1000)

            this.socketMain.on('disconnect', () => {
                console.log('Socket.IO connection closed.');
            });

            this.socketMain.on('error', error => {
                console.error('Socket.IO error:', error);
            });
        },

        connectSocketSecondaryIo() {
            this.socketSecondary = reconnectSecondarySocket();

            console.log("socket details socketSecondary", this.socketSecondary);

            if (!this.socketSecondary.connected) {
                console.log("Forcing socket connection...");
                this.socketSecondary.connect();
            }

            this.socketSecondary.on('connect', () => {
                console.log('socket details socketSecondary connection established.');
                this.socketSecondary.emit('message', 'Hello server, send me polyData!');
            });

            this.socketSecondary.on('polygonData', data => {
                console.log("Received polygonData:", data);
                this.latestData = data;
                this.selectedCallStrike = data?.callput1;
                this.selectedPutStrike = data?.putcall1;
            });

            this.socketSecondary.on('connect_error', (err) => {
                console.error('Socket connection error:', err);
            });

            this.socketSecondary.on('disconnect', (reason) => {
                console.warn('Socket disconnected:', reason);
            });
        },
    }
}
</script>

<style scoped>
.spinning {
    animation: spin 1s linear infinite;
}

td {
    padding: 0.2rem 0.5rem !important;
}

/* Scrollable table styles */
.data-table-container {
    width: 100%;
    height: 270px;
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd;
}

.data-table {
    width: 100%;
    height: auto;
}

.data-table thead {
    position: sticky;
    top: 0px;
    z-index: 2
}

.data-table tbody .sticky-tr-0 {
    position: sticky;
    top: 1px;
    z-index: 2
}

.data-table tbody .sticky-tr {
    position: sticky;
    top: 41px;
    z-index: 2
}

.data-table th,
.data-table td {
    width: auto;
    padding: 8px;
    outline: 1px solid #ddd;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

select {
    height: 38px;
}

button {
    height: 35px;
    text-align: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>